import { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export interface CityResponse {
  country?: CountryResponse;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  name?: string | null;
  postalCode?: string | null;
  regionKey?: string | null;
  state?: StateResponse;
  /** @format int32 */
  stateKey?: number | null;
}

export interface CityResponseResourceResponseMessage {
  message?: string;
  resource?: CityResponse;
  /** @format int32 */
  statusCode: number;
}

export interface CountryResponse {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface CountryResponsePagedData {
  list?: CountryResponse[];
  pagination?: Pagination;
}

export interface CountryResponseResourceResponseMessage {
  message?: string;
  resource?: CountryResponse;
  /** @format int32 */
  statusCode: number;
}

export interface CreateCityRequest {
  /** @format int32 */
  countryKey: number;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 8
   */
  postalCode: string;
  /** @format int32 */
  stateKey?: number | null;
}

export interface CreateCountryRequest {
  /**
   * @minLength 0
   * @maxLength 4
   */
  abbreviation: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface ProblemDetails {
  detail?: string | null;
  instance?: string | null;
  /** @format int32 */
  status?: number | null;
  title?: string | null;
  type?: string | null;
  [key: string]: any;
}

export interface RenovateZipCodeRequest {
  /**
   * @minLength 0
   * @maxLength 45
   */
  city: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  country: string;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  /**
   * @minLength 0
   * @maxLength 8
   */
  postalCode: string;
  /** @format int32 */
  stateKey: number;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface StateResponse {
  abbreviation?: string;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface UInt32StringKeyValuePair {
  /** @format int32 */
  key: number;
  value?: string;
}

export interface UpdateCityRequest {
  /** @format int32 */
  countryKey: number;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  /**
   * @minLength 0
   * @maxLength 45
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 8
   */
  postalCode: string;
  /** @format int32 */
  stateKey?: number | null;
}

export interface UpdateCountryRequest {
  /**
   * @minLength 0
   * @maxLength 4
   */
  abbreviation: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
}

export interface UploadByCsvResponse {
  /** @format int32 */
  added: number;
  /** @format int32 */
  deleted: number;
  /** @format int32 */
  processed: number;
  /** @format int32 */
  rejected: number;
  /** @format int32 */
  updated: number;
}

export interface V1CitiesListParams {
  name?: string;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int32 */
  stateKey?: number;
}

export interface V1CitiesUploadByCsvCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V1CountriesAutocompleteListParams {
  /** @default false */
  abbreviation?: boolean;
  term?: string;
}

export interface V1CountriesGetbynamePaginatedListParams {
  name?: string;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1CountriesListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1StatesAutocompleteListParams {
  /** @default false */
  abbreviation?: boolean;
  term?: string;
}

export interface V1StatesListParams {
  /** @format int32 */
  countryKey?: number;
}

export interface V1StatesUploadByCsvCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V1ZipcodesListParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1ZipcodesSearchPostalCodeListParams {
  /**
   * @minLength 2
   * @maxLength 8
   */
  postalCode: string;
}

export interface ZipCodeResponse {
  city?: string;
  country?: string;
  /** @format int32 */
  key: number;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  postalCode?: string;
  state?: StateResponse;
  /** @format int32 */
  stateKey?: number | null;
}

export interface ZipCodeResponsePagedData {
  list?: ZipCodeResponse[];
  pagination?: Pagination;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags City
 * @name V1CitiesCreate
 * @summary  (Roles: rdb1, rdb2, rdb3, rdb4, rdb5, administrator, developer)
 * @request POST:/rdb/v1/cities
 * @secure
 */
export const v1CitiesCreate = (
  client: AxiosInstance,
  data: CreateCityRequest,
  params: RequestParams = {},
) =>
  client
    .request<CityResponseResourceResponseMessage>({
      url: `/rdb/v1/cities`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags City
 * @name V1CitiesDetail
 * @summary  (Roles: rdb1, rdb2, rdb3, rdb4, rdb5, administrator, developer)
 * @request GET:/rdb/v1/cities/{key}
 * @secure
 */
export const v1CitiesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<CityResponse>({
      url: `/rdb/v1/cities/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags City
 * @name V1CitiesList
 * @summary  (Roles: rdb1, rdb2, rdb3, rdb4, rdb5, administrator, developer)
 * @request GET:/rdb/v1/cities
 * @secure
 */
export const v1CitiesList = (
  client: AxiosInstance,
  query: V1CitiesListParams,
  params: RequestParams = {},
) =>
  client
    .request<CityResponse[]>({
      url: `/rdb/v1/cities`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags City
 * @name V1CitiesUpdate
 * @summary  (Roles: rdb1, rdb2, rdb3, rdb4, rdb5, administrator, developer)
 * @request PUT:/rdb/v1/cities/{key}
 * @secure
 */
export const v1CitiesUpdate = (
  client: AxiosInstance,
  key: number,
  data: UpdateCityRequest,
  params: RequestParams = {},
) =>
  client
    .request<CityResponseResourceResponseMessage>({
      url: `/rdb/v1/cities/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags City
 * @name V1CitiesUploadByCsvCreate
 * @summary  (Roles: rdb1, rdb2, rdb3, rdb4, rdb5, administrator, developer)
 * @request POST:/rdb/v1/cities/uploadByCsv
 * @secure
 */
export const v1CitiesUploadByCsvCreate = (
  client: AxiosInstance,
  data: V1CitiesUploadByCsvCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UploadByCsvResponse>({
      url: `/rdb/v1/cities/uploadByCsv`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Country
 * @name V1CountriesAutocompleteList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/countries/autocomplete
 * @secure
 */
export const v1CountriesAutocompleteList = (
  client: AxiosInstance,
  query: V1CountriesAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<UInt32StringKeyValuePair[]>({
      url: `/rdb/v1/countries/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Country
 * @name V1CountriesCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/countries
 * @secure
 */
export const v1CountriesCreate = (
  client: AxiosInstance,
  data: CreateCountryRequest,
  params: RequestParams = {},
) =>
  client
    .request<CountryResponseResourceResponseMessage>({
      url: `/rdb/v1/countries`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Country
 * @name V1CountriesDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/countries/{key}
 * @secure
 */
export const v1CountriesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<CountryResponse>({
      url: `/rdb/v1/countries/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Country
 * @name V1CountriesGetbynamePaginatedList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/countries/getbyname-paginated
 * @secure
 */
export const v1CountriesGetbynamePaginatedList = (
  client: AxiosInstance,
  query: V1CountriesGetbynamePaginatedListParams,
  params: RequestParams = {},
) =>
  client
    .request<CountryResponsePagedData>({
      url: `/rdb/v1/countries/getbyname-paginated`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Country
 * @name V1CountriesList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/countries
 * @secure
 */
export const v1CountriesList = (
  client: AxiosInstance,
  query: V1CountriesListParams,
  params: RequestParams = {},
) =>
  client
    .request<CountryResponse[]>({
      url: `/rdb/v1/countries`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Country
 * @name V1CountriesUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/countries/{key}
 * @secure
 */
export const v1CountriesUpdate = (
  client: AxiosInstance,
  key: number,
  data: UpdateCountryRequest,
  params: RequestParams = {},
) =>
  client
    .request<CountryResponseResourceResponseMessage>({
      url: `/rdb/v1/countries/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags State
 * @name V1StatesAutocompleteList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/states/autocomplete
 * @secure
 */
export const v1StatesAutocompleteList = (
  client: AxiosInstance,
  query: V1StatesAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<UInt32StringKeyValuePair[]>({
      url: `/rdb/v1/states/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags State
 * @name V1StatesDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request GET:/rdb/v1/states/{key}
 * @secure
 */
export const v1StatesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<StateResponse>({
      url: `/rdb/v1/states/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags State
 * @name V1StatesList
 * @request GET:/rdb/v1/states
 */
export const v1StatesList = (
  client: AxiosInstance,
  query: V1StatesListParams,
  params: RequestParams = {},
) =>
  client
    .request<StateResponse[]>({
      url: `/rdb/v1/states`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags State
 * @name V1StatesUploadByCsvCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/states/uploadByCsv
 * @secure
 */
export const v1StatesUploadByCsvCreate = (
  client: AxiosInstance,
  data: V1StatesUploadByCsvCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UploadByCsvResponse>({
      url: `/rdb/v1/states/uploadByCsv`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ZipCode
 * @name V1ZipcodesCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request POST:/rdb/v1/zipcodes
 * @secure
 */
export const v1ZipcodesCreate = (
  client: AxiosInstance,
  data: RenovateZipCodeRequest,
  params: RequestParams = {},
) =>
  client
    .request<ZipCodeResponse>({
      url: `/rdb/v1/zipcodes`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ZipCode
 * @name V1ZipcodesDetail
 * @request GET:/rdb/v1/zipcodes/{key}
 */
export const v1ZipcodesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ZipCodeResponse>({
      url: `/rdb/v1/zipcodes/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ZipCode
 * @name V1ZipcodesList
 * @request GET:/rdb/v1/zipcodes
 */
export const v1ZipcodesList = (
  client: AxiosInstance,
  query: V1ZipcodesListParams,
  params: RequestParams = {},
) =>
  client
    .request<ZipCodeResponsePagedData>({
      url: `/rdb/v1/zipcodes`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ZipCode
 * @name V1ZipcodesSearchPostalCodeList
 * @request GET:/rdb/v1/zipcodes/search-postal-code
 */
export const v1ZipcodesSearchPostalCodeList = (
  client: AxiosInstance,
  query: V1ZipcodesSearchPostalCodeListParams,
  params: RequestParams = {},
) =>
  client
    .request<ZipCodeResponse>({
      url: `/rdb/v1/zipcodes/search-postal-code`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);
