import { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export enum AppTypes {
  Unknown = 'Unknown',
  Fan = 'Fan',
  Elite = 'Elite',
  Web = 'Web',
}

export interface AppVersionFilterRes {
  app: AppTypes;
  device: DeviceType;
  /** @format int32 */
  page: number;
}

export interface AppVersionRes {
  app: AppTypes;
  current: boolean;
  device: DeviceType;
  /** @format int64 */
  key: number;
  minimum: boolean;
  version: string;
}

export interface AppVersionResAppVersionFilterResRelatedPagedData {
  list?: AppVersionRes[];
  pagination?: Pagination;
  relatedModel?: AppVersionFilterRes;
}

export enum DeviceType {
  Android = 'Android',
  Browser = 'Browser',
  IOS = 'iOS',
}

export interface LatestVersionInfoRes {
  app: AppTypes;
  currentVersion?: string | null;
  device: DeviceType;
  minimumVersion?: string | null;
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface RenAppVersionReq {
  app: AppTypes;
  current: boolean;
  device: DeviceType;
  /** @format int64 */
  key?: number | null;
  minimum: boolean;
  version: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface V1AppInfoVersionsLatestListParams {
  app: AppTypes;
  device: DeviceType;
}

export interface V1AppInfoVersionsListParams {
  app: AppTypes;
  device: DeviceType;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page: number;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags AppInfo
 * @name V1AppInfoVersionsDetail
 * @summary  (Roles: developer)
 * @request GET:/support/v1/app-info/versions/{key}
 * @secure
 */
export const v1AppInfoVersionsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<AppVersionRes>({
      url: `/support/v1/app-info/versions/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AppInfo
 * @name V1AppInfoVersionsLatestList
 * @request GET:/support/v1/app-info/versions/latest
 */
export const v1AppInfoVersionsLatestList = (
  client: AxiosInstance,
  query: V1AppInfoVersionsLatestListParams,
  params: RequestParams = {},
) =>
  client
    .request<LatestVersionInfoRes>({
      url: `/support/v1/app-info/versions/latest`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AppInfo
 * @name V1AppInfoVersionsList
 * @summary  (Roles: developer)
 * @request GET:/support/v1/app-info/versions
 * @secure
 */
export const v1AppInfoVersionsList = (
  client: AxiosInstance,
  query: V1AppInfoVersionsListParams,
  params: RequestParams = {},
) =>
  client
    .request<AppVersionResAppVersionFilterResRelatedPagedData>({
      url: `/support/v1/app-info/versions`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AppInfo
 * @name V1AppInfoVersionsRenovateUpdate
 * @summary  (Roles: developer)
 * @request PUT:/support/v1/app-info/versions/renovate
 * @secure
 */
export const v1AppInfoVersionsRenovateUpdate = (
  client: AxiosInstance,
  data: RenAppVersionReq,
  params: RequestParams = {},
) =>
  client
    .request<AppVersionRes>({
      url: `/support/v1/app-info/versions/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);
