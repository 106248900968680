import { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export interface Action {
  actionName?: string | null;
  icon?: string | null;
  title?: string | null;
}

export interface AndroidConfig {
  collapseKey?: string | null;
  data?: Record<string, string>;
  fcmOptions?: AndroidFcmOptions;
  notification?: AndroidNotification;
  priority?: Priority;
  restrictedPackageName?: string | null;
  /** @format date-span */
  timeToLive?: string | null;
}

export interface AndroidFcmOptions {
  analyticsLabel?: string | null;
}

export interface AndroidNotification {
  body?: string | null;
  bodyLocArgs?: string[] | null;
  bodyLocKey?: string | null;
  channelId?: string | null;
  clickAction?: string | null;
  color?: string | null;
  defaultLightSettings: boolean;
  defaultSound: boolean;
  defaultVibrateTimings: boolean;
  /** @format date-time */
  eventTimestamp: string;
  icon?: string | null;
  imageUrl?: string | null;
  lightSettings?: LightSettings;
  localOnly: boolean;
  /** @format int32 */
  notificationCount?: number | null;
  priority?: NotificationPriority;
  sound?: string | null;
  sticky: boolean;
  tag?: string | null;
  ticker?: string | null;
  title?: string | null;
  titleLocArgs?: string[] | null;
  titleLocKey?: string | null;
  vibrateTimingsMillis?: number[] | null;
  visibility?: NotificationVisibility;
}

export interface ApnsConfig {
  aps?: Aps;
  customData?: Record<string, any>;
  fcmOptions?: ApnsFcmOptions;
  headers?: Record<string, string>;
}

export interface ApnsFcmOptions {
  analyticsLabel?: string | null;
  imageUrl?: string | null;
}

export interface Aps {
  alert?: ApsAlert;
  alertString?: string | null;
  /** @format int32 */
  badge?: number | null;
  category?: string | null;
  contentAvailable: boolean;
  criticalSound?: CriticalSound;
  customData?: Record<string, any>;
  mutableContent: boolean;
  sound?: string | null;
  threadId?: string | null;
}

export interface ApsAlert {
  actionLocKey?: string | null;
  body?: string | null;
  launchImage?: string | null;
  locArgs?: string[] | null;
  locKey?: string | null;
  subtitle?: string | null;
  subtitleLocArgs?: string[] | null;
  subtitleLocKey?: string | null;
  title?: string | null;
  titleLocArgs?: string[] | null;
  titleLocKey?: string | null;
}

export interface ArticleLogResponse {
  fullPath?: string | null;
  /** @format int64 */
  key: number;
  title?: string | null;
}

export interface ArticleNotificationDetail {
  body: string | null;
  imageUrl: string | null;
  /** @format int64 */
  key: number;
  messages: Message[];
  notificationStatus: NotificationStatuses;
  notificationType: NotificationTypes;
  primaryCategory: SlimCategoryResponse;
  status: string | null;
  /** @deprecated */
  terms?: string[] | null;
  title: string | null;
}

export interface ArticleNotificationLogResponse {
  body: string | null;
  /** @format date-time */
  createdAt: string;
  error?: string | null;
  eventType: NotificationStatuses;
  /** @format int64 */
  key: number;
  post?: ArticleLogResponse;
  recipients: ArticleNotificationRecipients;
  title: string | null;
  topics?: string[] | null;
  user: SimpleUserResponse;
}

export interface ArticleNotificationLogResponsePagedData {
  list?: ArticleNotificationLogResponse[];
  pagination?: Pagination;
}

export interface ArticleNotificationRecipients {
  all: boolean;
  orgKeys?: number[] | null;
  siteKeys?: number[] | null;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface CategoryResponse {
  /**
   * Count usage of term
   * @format int64
   */
  count: number;
  /** full url of category */
  fullUrl?: string;
  /**
   * Key of term
   * @format int64
   */
  key: number;
  /** Name of term */
  name?: string;
  /**
   * Parent of term
   * @format int64
   */
  parent: number;
  /** Slug of term */
  slug?: string;
  /** Taxonomy of term */
  taxonomy?: string;
  /**
   * Term Key of headline
   * @format int64
   */
  termKey: number;
}

export enum CategoryTypes {
  Unknown = 'Unknown',
  Home = 'Home',
  Sport = 'Sport',
  Recruiting = 'Recruiting',
  Newsletter = 'Newsletter',
  HighSchool = 'HighSchool',
}

export interface CriticalSound {
  critical: boolean;
  name?: string | null;
  /** @format double */
  volume?: number | null;
}

export enum Direction {
  Auto = 'Auto',
  LeftToRight = 'LeftToRight',
  RightToLeft = 'RightToLeft',
}

export interface FcmOptions {
  analyticsLabel?: string | null;
}

export interface HeadlineResponse {
  author?: UserProfileBase;
  /** Body of headline */
  body?: string | null;
  /** List of Categories of headline */
  categories?: CategoryResponse[];
  /** Excerpt/Summary of headline */
  excerpt?: string | null;
  featuredImage?: ImageResponse;
  /** url of headline */
  fullUrl?: string | null;
  /** Bool for YouTube Video */
  hasYouTubeVideo: boolean;
  /** Body of headline */
  head?: string | null;
  /** Bool for Hide Videos */
  hideVideos: boolean;
  /** Denotes if the content is marked as premium or not */
  isPremium: boolean;
  /**
   * Key of headline
   * @format int64
   */
  key: number;
  /**
   * The Modified date in WP settings
   * @format date-time
   */
  modifiedDate: string;
  /**
   * The Modified date in GMT
   * @format date-time
   */
  modifiedDateGMT: string;
  /**
   * The Post date in WP settings
   * @format date-time
   */
  postDate: string;
  /**
   * The Post date in GMT
   * @format date-time
   */
  postDateGMT: string;
  primaryCategory?: CategoryResponse;
  /**
   * Primary category key of headline
   * @format int64
   */
  primaryCategoryKey?: number | null;
  /** Slug of headline */
  slug?: string | null;
  /** Status of headline */
  status?: string | null;
  /** List of tags of headline */
  tags?: TermResponse[];
  /** Title of headline */
  title?: string | null;
}

export interface HeadlineResponseOrganizationResponseRelatedPagedData {
  list?: HeadlineResponse[];
  pagination?: Pagination;
  relatedModel?: OrganizationResponse;
}

export interface HeadlineResponsePagedData {
  list?: HeadlineResponse[];
  pagination?: Pagination;
}

export interface HeadlineResponsePersonBaseRelatedPagedData {
  list?: HeadlineResponse[];
  pagination?: Pagination;
  relatedModel?: PersonBase;
}

export interface HeadlinesAdminSearchRequest {
  /**
   * Limit the results to posts created before this date. Defaults to null.
   * @format date-time
   */
  endDate?: string | null;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page: number;
  /** @format int64 */
  primaryCategoryKey?: number | null;
  /**
   * When `true`, only articles sent via the WordPress method will return. If `false`, only articles sent via the
   * manual notification tooling will be returned. `null` returns both.
   *
   * Note: A single article can return for all values set here in the case of the publisher sending the notification
   * from WordPress then a subsequent notification being sent manually from the tooling.
   */
  sentByPublisher?: boolean | null;
  /** @format int64 */
  siteKey?: number | null;
  /**
   * Limit the results to posts created after this date. Defaults to four weeks ago.
   * @format date-time
   */
  startDate?: string | null;
  /** Filters headlines by title. This is a "starts with" search. */
  title?: string | null;
}

export interface HeadlinesAdminSearchResponse {
  author: UserProfileResponseRecord;
  featuredImage: ImageResponse;
  /** @format int64 */
  key: number;
  notificationStatus: NotificationStatuses;
  /** @format int64 */
  notificationStatusDate: number | null;
  /** @format int32 */
  postDate: number;
  primaryCategory: SlimCategoryResponse;
  sentByPublisher: boolean;
  sentManually: boolean;
  subject: string | null;
  url: string | null;
}

export interface HeadlinesAdminSearchResponseHeadlinesAdminSearchRequestRelatedPagedData {
  list?: HeadlinesAdminSearchResponse[];
  pagination?: Pagination;
  relatedModel?: HeadlinesAdminSearchRequest;
}

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export interface LightSettings {
  color?: string | null;
  /** @format int64 */
  lightOffDurationMillis: number;
  /** @format int64 */
  lightOnDurationMillis: number;
}

export interface Message {
  android?: AndroidConfig;
  apns?: ApnsConfig;
  condition?: string | null;
  data?: Record<string, string>;
  fcmOptions?: FcmOptions;
  notification?: Notification;
  token?: string | null;
  topic?: string | null;
  webpush?: WebpushConfig;
}

export interface Notification {
  body?: string | null;
  imageUrl?: string | null;
  title?: string | null;
}

export enum NotificationPriority {
  MIN = 'MIN',
  LOW = 'LOW',
  DEFAULT = 'DEFAULT',
  HIGH = 'HIGH',
  MAX = 'MAX',
}

export enum NotificationStatuses {
  NotSent = 'NotSent',
  NeedsScheduled = 'NeedsScheduled',
  Scheduled = 'Scheduled',
  Sending = 'Sending',
  Sent = 'Sent',
  Canceling = 'Canceling',
  Canceled = 'Canceled',
  Error = 'Error',
}

export enum NotificationTypes {
  None = 'None',
  EveryoneElse = 'EveryoneElse',
  BreakingAndEveryoneElse = 'BreakingAndEveryoneElse',
}

export enum NotificationVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  SECRET = 'SECRET',
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PersonBase {
  /** @format int64 */
  key: number;
  lastName?: string;
  name?: string;
  slug?: string;
}

export enum Priority {
  High = 'High',
  Normal = 'Normal',
}

export interface RelatedHeadlineResponse {
  author?: SimpleAuthorResponse;
  excerpt?: string | null;
  featuredImage?: ImageResponse;
  /** url of headline */
  fullUrl?: string | null;
  isPlus: boolean;
  /** Denotes if the content is marked as premium or not */
  isPremium: boolean;
  /**
   * Key of headline
   * @format int64
   */
  key: number;
  /**
   * The Modified date in WP settings
   * @format date-time
   */
  modifiedDate: string;
  /**
   * The Modified date in GMT
   * @format date-time
   */
  modifiedDateGMT: string;
  /**
   * The Post date in WP settings
   * @format date-time
   */
  postDate: string;
  /**
   * The Post date in GMT
   * @format date-time
   */
  postDateGMT: string;
  primaryCategory?: CategoryResponse;
  /**
   * Primary category of headline
   * @format int64
   */
  primaryCategoryKey?: number | null;
  /** Slug of headline */
  slug?: string | null;
  /** Title of headline */
  title?: string | null;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface SendNotificationForPostRequest {
  body?: string | null;
  orgKeys?: number[] | null;
  sendToAll: boolean;
  siteKeys?: number[] | null;
  title: string;
}

export interface SimpleAuthorResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  slug?: string | null;
}

export interface SimpleHeadlineResponse {
  /**
   * Date the article was published
   * @format date-time
   */
  datePublishedGmt: string;
  /** url of headline */
  fullUrl?: string | null;
  /** Denotes if the content is marked as premium or not */
  isPremium: boolean;
  /**
   * Key of headline
   * @format int64
   */
  key: number;
  /** Slug of headline */
  slug?: string | null;
  /** Title of headline */
  title?: string | null;
}

export interface SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData {
  list?: SimpleHeadlineResponse[];
  pagination?: Pagination;
  relatedModel?: SimpleSiteResponseRecord;
}

export interface SimpleImageResponseRecord {
  /** @format int64 */
  key: number;
  url?: string | null;
}

export interface SimpleSiteResponseRecord {
  allowSubs: boolean;
  alternateAvatar?: AssetResponse;
  /** @format int64 */
  alternateAvatarKey?: number | null;
  alternateLogo?: AssetResponse;
  /** @format int64 */
  alternateLogoKey?: number | null;
  amazonAffiliateLink?: string | null;
  /** @format int64 */
  categoryKey?: number | null;
  defaultAsset?: AssetResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  defaultAvatar?: AssetResponse;
  /** @format int64 */
  defaultAvatarKey?: number | null;
  /** @format int64 */
  defaultTrendingListKey?: number | null;
  facebookProfile?: string | null;
  /** @format int32 */
  forumKey?: number | null;
  gTagId?: string | null;
  googleAnalyticsProjectId?: string | null;
  inIndex: boolean;
  instagramProfile?: string | null;
  isChannel: boolean;
  isNational: boolean;
  isTeam: boolean;
  /** @format int32 */
  key: number;
  keyPersons?: SimpleUserResponse[] | null;
  linkColor?: string | null;
  live: boolean;
  /** @format int32 */
  menuKey?: number | null;
  name?: string | null;
  /** @format int32 */
  organizationKey?: number | null;
  primaryColor?: string | null;
  /** @format int32 */
  primaryForumId?: number | null;
  resourceId?: string | null;
  secondaryColor?: string | null;
  /** @format int32 */
  siblingSiteKey?: number | null;
  slug?: string | null;
  subscribeText?: string | null;
  tagLine?: string | null;
  template: SiteTemplate;
  templateId?: string | null;
  tertiaryColor?: string | null;
  twitterHandle?: string | null;
  type: SiteType;
  url?: string | null;
  youTubeChannelId?: string | null;
  youtubeProfile?: string | null;
}

export interface SimpleUserResponse {
  displayName?: string | null;
  /** @format int64 */
  key: number;
  login?: string | null;
  niceName?: string | null;
}

export enum SiteTemplate {
  Feed = 'Feed',
  Traditional = 'Traditional',
}

export enum SiteType {
  National = 'National',
  Channel = 'Channel',
  Team = 'Team',
}

export interface SlimCategoryResponse {
  /** @format int64 */
  key: number;
  name?: string;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface TermResponse {
  /**
   * Count usage of term
   * @format int64
   */
  count: number;
  /**
   * Key of term
   * @format int64
   */
  key: number;
  /** Name of term */
  name?: string;
  /**
   * Parent of term
   * @format int64
   */
  parent: number;
  /** Slug of term */
  slug?: string;
  /** Taxonomy of term */
  taxonomy?: string;
  /**
   * Term Key of headline
   * @format int64
   */
  termKey: number;
}

export interface UserProfileBase {
  bio?: string | null;
  instagramHandle?: string | null;
  jobTitle?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  niceName?: string;
  profilePicture?: KeyUrlBase;
  profilePictureResponse?: ImageResponse;
  twitterHandle?: string | null;
  youtubeUrl?: string | null;
}

export interface UserProfileResponseRecord {
  bio?: string | null;
  instagramHandle?: string | null;
  jobTitle?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  niceName?: string | null;
  profileAsset?: AssetResponse;
  profilePicture?: SimpleImageResponseRecord;
  twitterHandle?: string | null;
  youtubeUrl?: string | null;
}

export interface V1AuthorsHeadlinesDetailParams {
  /** The author slug that you want headlines for headline */
  author: string;
  /**
   * The page number wanted
   * @format int32
   * @default 15
   */
  page?: number;
}

export interface V1AuthorsHeadlinesPaginatedDetailParams {
  /** The author slug that you want headlines for headline */
  author: string;
  /**
   * The page number wanted
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1CategoriesHeadlinesDetail2Params {
  /**
   * The category for the headline
   * @format int64
   */
  category: number;
  /**
   * @format int32
   * @min 0
   * @max 25
   * @default 25
   */
  limit?: number;
  /**
   * The page number wanted
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1CategoriesHeadlinesPaginatedDetailParams {
  /** @format int64 */
  category: number;
  /**
   * @format int32
   * @min 0
   * @max 25
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1CollectiveGroupsHeadlinesDetailParams {
  /** @format int64 */
  key: number;
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1HeadlinesAdminSearchListParams {
  /**
   * Limit the results to posts created before this date. Defaults to null.
   * @format date-time
   */
  endDate?: string;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int64 */
  primaryCategoryKey?: number;
  /**
   * When `true`, only articles sent via the WordPress method will return. If `false`, only articles sent via the
   * manual notification tooling will be returned. `null` returns both.
   *
   * Note: A single article can return for all values set here in the case of the publisher sending the notification
   * from WordPress then a subsequent notification being sent manually from the tooling.
   */
  sentByPublisher?: boolean;
  /** @format int64 */
  siteKey?: number;
  /**
   * Limit the results to posts created after this date. Defaults to four weeks ago.
   * @format date-time
   */
  startDate?: string;
  /** Filters headlines by title. This is a "starts with" search. */
  title?: string;
}

export interface V1HeadlinesNotificationLogsDetailParams {
  /** @format date-time */
  endDate?: string;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  page?: number;
  /** @format int64 */
  postKey: number;
  sortDirection?: SortDirection;
  /** @format date-time */
  startDate?: string;
  statuses?: NotificationStatuses[];
}

export interface V1HeadlinesNotificationLogsListParams {
  /** @format date-time */
  endDate?: string;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  page?: number;
  sortDirection?: SortDirection;
  /** @format date-time */
  startDate?: string;
  statuses?: NotificationStatuses[];
}

export interface V1HeadlinesRelatedHeadlinesDetailParams {
  /** @format int64 */
  headlineKey: number;
  /**
   * @format int32
   * @min 0
   * @max 50
   * @default 50
   */
  limit?: number;
}

export interface V1OrganizationsHeadlinesDetailParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /** @format int32 */
  orgKey: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @default false */
  usePrimaryCategory?: boolean;
  /** @default false */
  useTag?: boolean;
  /** @default false */
  useTeamSite?: boolean;
}

export interface V1PeopleHeadlinesDetailParams {
  /**
   * The person key for the headline
   * @format int64
   */
  key: number;
  /**
   * @format int32
   * @min 0
   * @max 25
   * @default 25
   */
  limit?: number;
  /**
   * The page number wanted
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1PeopleHeadlinesPaginatedDetailParams {
  /** @format int64 */
  key: number;
  /**
   * @format int32
   * @min 0
   * @max 25
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1SitesHeadlinesForCategoryTypeDetailParams {
  /**
   * @format int32
   * @min 0
   * @max 25
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  siteKey: number;
  type?: CategoryTypes;
}

export interface V1SitesPremiumHeadlinesListParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  siteKey?: number;
}

export interface WebpushConfig {
  data?: Record<string, string>;
  fcmOptions?: WebpushFcmOptions;
  headers?: Record<string, string>;
  notification?: WebpushNotification;
}

export interface WebpushFcmOptions {
  link?: string | null;
}

export interface WebpushNotification {
  actions?: Action[] | null;
  badge?: string | null;
  body?: string | null;
  customData?: Record<string, any>;
  data?: any;
  direction?: Direction;
  icon?: string | null;
  image?: string | null;
  language?: string | null;
  renotify?: boolean | null;
  requireInteraction?: boolean | null;
  silent?: boolean | null;
  tag?: string | null;
  /** @format int64 */
  timestampMillis?: number | null;
  title?: string | null;
  vibrate?: number[] | null;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags Headline
 * @name V1AuthorsHeadlinesDetail
 * @summary Gets the headline data based on the slug and user access
 * @request GET:/content/v1/authors/{author}/headlines
 */
export const v1AuthorsHeadlinesDetail = (
  client: AxiosInstance,
  { author, ...query }: V1AuthorsHeadlinesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponse[]>({
      url: `/content/v1/authors/${author}/headlines`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1AuthorsHeadlinesPaginatedDetail
 * @summary Gets the headline data based on the slug and user access
 * @request GET:/content/v1/authors/{author}/headlines-paginated
 */
export const v1AuthorsHeadlinesPaginatedDetail = (
  client: AxiosInstance,
  { author, ...query }: V1AuthorsHeadlinesPaginatedDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponsePagedData>({
      url: `/content/v1/authors/${author}/headlines-paginated`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1CategoriesFeaturedHeadlinesDetail
 * @summary Gets the headline data based on the slug and user access
 * @request GET:/content/v1/categories/{category}/featured-headlines
 */
export const v1CategoriesFeaturedHeadlinesDetail = (
  client: AxiosInstance,
  category: number,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponse[]>({
      url: `/content/v1/categories/${category}/featured-headlines`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1CategoriesHeadlinesDetail
 * @summary Gets the headline data based on the slug and user access
 * @request GET:/content/v1/categories/{category}/headlines/{slug}
 */
export const v1CategoriesHeadlinesDetail = (
  client: AxiosInstance,
  category: number,
  slug: string,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponse>({
      url: `/content/v1/categories/${category}/headlines/${slug}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1CategoriesHeadlinesDetail2
 * @summary Gets the headline data based on the slug and user access
 * @request GET:/content/v1/categories/{category}/headlines
 * @originalName v1CategoriesHeadlinesDetail
 * @duplicate
 */
export const v1CategoriesHeadlinesDetail2 = (
  client: AxiosInstance,
  { category, ...query }: V1CategoriesHeadlinesDetail2Params,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponse[]>({
      url: `/content/v1/categories/${category}/headlines`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1CategoriesHeadlinesPaginatedDetail
 * @request GET:/content/v1/categories/{category}/headlines-paginated
 */
export const v1CategoriesHeadlinesPaginatedDetail = (
  client: AxiosInstance,
  { category, ...query }: V1CategoriesHeadlinesPaginatedDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponsePagedData>({
      url: `/content/v1/categories/${category}/headlines-paginated`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1CollectiveGroupsHeadlinesDetail
 * @request GET:/content/v1/collective-groups/{key}/headlines
 */
export const v1CollectiveGroupsHeadlinesDetail = (
  client: AxiosInstance,
  { key, ...query }: V1CollectiveGroupsHeadlinesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponsePagedData>({
      url: `/content/v1/collective-groups/${key}/headlines`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1HeadlinesAdminSearchList
 * @summary  (Roles: developer, notifications, rdb5, social_admin)
 * @request GET:/content/v1/headlines/admin-search
 * @secure
 */
export const v1HeadlinesAdminSearchList = (
  client: AxiosInstance,
  query: V1HeadlinesAdminSearchListParams,
  params: RequestParams = {},
) =>
  client
    .request<HeadlinesAdminSearchResponseHeadlinesAdminSearchRequestRelatedPagedData>(
      {
        url: `/content/v1/headlines/admin-search`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1HeadlinesNotificationCreate
 * @summary  (Roles: developer, notifications, rdb5, social_admin)
 * @request POST:/content/v1/headlines/{postKey}/notification
 * @secure
 */
export const v1HeadlinesNotificationCreate = (
  client: AxiosInstance,
  postKey: number,
  data: SendNotificationForPostRequest,
  params: RequestParams = {},
) =>
  client
    .request<ArticleNotificationLogResponse>({
      url: `/content/v1/headlines/${postKey}/notification`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1HeadlinesNotificationDetailDetail
 * @summary  (Roles: developer, notifications, rdb5, social_admin)
 * @request GET:/content/v1/headlines/{postKey}/notification-detail
 * @secure
 */
export const v1HeadlinesNotificationDetailDetail = (
  client: AxiosInstance,
  postKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ArticleNotificationDetail>({
      url: `/content/v1/headlines/${postKey}/notification-detail`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1HeadlinesNotificationLogsDetail
 * @summary  (Roles: developer, notifications, rdb5, social_admin)
 * @request GET:/content/v1/headlines/{postKey}/notification-logs
 * @secure
 */
export const v1HeadlinesNotificationLogsDetail = (
  client: AxiosInstance,
  { postKey, ...query }: V1HeadlinesNotificationLogsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ArticleNotificationLogResponsePagedData>({
      url: `/content/v1/headlines/${postKey}/notification-logs`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1HeadlinesNotificationLogsList
 * @summary  (Roles: developer)
 * @request GET:/content/v1/headlines/notification-logs
 * @secure
 */
export const v1HeadlinesNotificationLogsList = (
  client: AxiosInstance,
  query: V1HeadlinesNotificationLogsListParams,
  params: RequestParams = {},
) =>
  client
    .request<ArticleNotificationLogResponsePagedData>({
      url: `/content/v1/headlines/notification-logs`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1HeadlinesRelatedHeadlinesDetail
 * @request GET:/content/v1/headlines/{headlineKey}/related-headlines
 */
export const v1HeadlinesRelatedHeadlinesDetail = (
  client: AxiosInstance,
  { headlineKey, ...query }: V1HeadlinesRelatedHeadlinesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<RelatedHeadlineResponse[]>({
      url: `/content/v1/headlines/${headlineKey}/related-headlines`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1OrganizationsHeadlinesDetail
 * @request GET:/content/v1/organizations/{orgKey}/headlines
 */
export const v1OrganizationsHeadlinesDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V1OrganizationsHeadlinesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponseOrganizationResponseRelatedPagedData>({
      url: `/content/v1/organizations/${orgKey}/headlines`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1PeopleHeadlinesDetail
 * @summary Gets the headline data based on the slug and user access
 * @request GET:/content/v1/people/{key}/headlines
 */
export const v1PeopleHeadlinesDetail = (
  client: AxiosInstance,
  { key, ...query }: V1PeopleHeadlinesDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponsePersonBaseRelatedPagedData>({
      url: `/content/v1/people/${key}/headlines`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1PeopleHeadlinesPaginatedDetail
 * @request GET:/content/v1/people/{key}/headlines-paginated
 */
export const v1PeopleHeadlinesPaginatedDetail = (
  client: AxiosInstance,
  { key, ...query }: V1PeopleHeadlinesPaginatedDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponsePersonBaseRelatedPagedData>({
      url: `/content/v1/people/${key}/headlines-paginated`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1SitesHeadlinesForCategoryTypeDetail
 * @request GET:/content/v1/sites/{siteKey}/headlines-for-category-type
 */
export const v1SitesHeadlinesForCategoryTypeDetail = (
  client: AxiosInstance,
  { siteKey, ...query }: V1SitesHeadlinesForCategoryTypeDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<HeadlineResponse[]>({
      url: `/content/v1/sites/${siteKey}/headlines-for-category-type`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Headline
 * @name V1SitesPremiumHeadlinesList
 * @request GET:/content/v1/sites/premium-headlines
 */
export const v1SitesPremiumHeadlinesList = (
  client: AxiosInstance,
  query: V1SitesPremiumHeadlinesListParams,
  params: RequestParams = {},
) =>
  client
    .request<SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData>({
      url: `/content/v1/sites/premium-headlines`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);
