import { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export interface CompareUserJwtRes {
  /**
   * Response representing a user and the JWT
   * associated
   */
  dbJwt: UserJwtResponse;
  /**
   * Response representing a user and the JWT
   * associated
   */
  pianoJwt: UserJwtResponse;
}

export interface CreateServiceAccountTokenRequest {
  /** @format date-time */
  expiresAt?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
  /** @minItems 1 */
  roles: string[];
}

export interface DiagramGenerationRequest {
  /** @minLength 1 */
  type: string;
}

export interface ServiceAccountJwtResponse {
  /** The JWT to use */
  token?: string;
}

/**
 * Response representing a user and the JWT
 * associated
 */
export interface UserJwtResponse {
  /** The display name of the user */
  displayName?: string | null;
  email: string | null;
  /** Does the user have at least one active subscription */
  hasActiveSubscription: boolean;
  latestTransactionId?: string;
  /** @format int64 */
  personKey?: number | null;
  personKeys?: number[];
  /** The pianoId of the user */
  pianoId?: string | null;
  promotionEligible: boolean;
  /**
   * The time when the Token will be expired
   * @format date-time
   */
  refreshBefore: string;
  /** The refresh token used to get a new Token */
  refreshToken?: string;
  /**
   * The date/time the refresh token expires
   * @format date-time
   */
  refreshTokenExpiry: string;
  /** The list of string roles */
  roles?: string[];
  sites?: number[];
  /** What is the max type of subscription a user has */
  subscriptionMaxType?: string;
  /** List of active subscription keys */
  subscriptions?: number[];
  /** The JWT to use */
  token?: string;
  /**
   * The id of the user
   * @format int64
   */
  userId: number;
  /** The username of the user */
  username?: string;
}

export interface V1DevToolsExistingCacheKeysListParams {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1DevToolsGetForumsCountCreateParams {
  /** @format date-time */
  from?: string;
  nodeIds?: number[];
  /** @format date-time */
  to?: string;
  type?: string[];
  userIds?: number[];
}

export type V1DevToolsGetNotifHandlerOrderCreatePayload = string;

export interface V1DevToolsPianoTestWebhookModelsListParams {
  /** Tests only the specified event. */
  event?: string;
  /** Ignore "piano_id_user_custom_fields_updated" because there are a lot of useless records. */
  filterNoise?: boolean;
  /** @format int32 */
  limit?: number;
  /** Do not return successful deserializations. */
  onlyErrors?: boolean;
  /** @format int32 */
  page?: number;
  /** Tests only the specified type. */
  type?: string;
}

export type V1DevToolsPurgeCacheForTagsCreatePayload = string[];

export type V1DevToolsPurgeCacheKeysCreatePayload = string[];

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsExistingCacheKeysList
 * @summary  (Roles: developer)
 * @request GET:/support/v1/dev-tools/existing-cache-keys
 * @secure
 */
export const v1DevToolsExistingCacheKeysList = (
  client: AxiosInstance,
  query: V1DevToolsExistingCacheKeysListParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/existing-cache-keys`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsGenerateDiagramCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/generate-diagram
 * @secure
 */
export const v1DevToolsGenerateDiagramCreate = (
  client: AxiosInstance,
  data: DiagramGenerationRequest,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/generate-diagram`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsGenerateServiceAccountTokenCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/generate-service-account-token
 * @secure
 */
export const v1DevToolsGenerateServiceAccountTokenCreate = (
  client: AxiosInstance,
  data: CreateServiceAccountTokenRequest,
  params: RequestParams = {},
) =>
  client
    .request<ServiceAccountJwtResponse>({
      url: `/support/v1/dev-tools/generate-service-account-token`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsGetForumsCountCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/get-forums-count
 * @secure
 */
export const v1DevToolsGetForumsCountCreate = (
  client: AxiosInstance,
  query: V1DevToolsGetForumsCountCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/get-forums-count`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsGetNotifHandlerOrderCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/get-notif-handler-order
 * @secure
 */
export const v1DevToolsGetNotifHandlerOrderCreate = (
  client: AxiosInstance,
  data: V1DevToolsGetNotifHandlerOrderCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/get-notif-handler-order`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsPianoTestWebhookModelsList
 * @summary Iterates through the webhook log and attempts to deserialize the request body.
 * @request GET:/support/v1/dev-tools/piano/test-webhook-models
 * @secure
 */
export const v1DevToolsPianoTestWebhookModelsList = (
  client: AxiosInstance,
  query: V1DevToolsPianoTestWebhookModelsListParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/piano/test-webhook-models`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsPurgeCacheForTagsCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/purge-cache-for-tags
 * @secure
 */
export const v1DevToolsPurgeCacheForTagsCreate = (
  client: AxiosInstance,
  data: V1DevToolsPurgeCacheForTagsCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/purge-cache-for-tags`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsPurgeCacheKeysCreate
 * @summary  (Roles: developer)
 * @request POST:/support/v1/dev-tools/purge-cache-keys
 * @secure
 */
export const v1DevToolsPurgeCacheKeysCreate = (
  client: AxiosInstance,
  data: V1DevToolsPurgeCacheKeysCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/support/v1/dev-tools/purge-cache-keys`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags DevTools
 * @name V1DevToolsUsersCompareJwtsDetail
 * @summary  (Roles: developer)
 * @request GET:/support/v1/dev-tools/users/{userKey}/compare-jwts
 * @secure
 */
export const v1DevToolsUsersCompareJwtsDetail = (
  client: AxiosInstance,
  userKey: number,
  params: RequestParams = {},
) =>
  client
    .request<CompareUserJwtRes>({
      url: `/support/v1/dev-tools/users/${userKey}/compare-jwts`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);
