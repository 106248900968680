import { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export interface ActiveGameStatusResponse {
  currentPeriod?: string | null;
  down?: string | null;
  possession?: string | null;
  timeRemaining?: string | null;
  yardline?: string | null;
  yardsRemaining?: string | null;
}

export interface AdminGameRes {
  /** @format int32 */
  awayOrgKey?: number | null;
  awayOrganization?: OrganizationResponse;
  /** @format int32 */
  awayScore?: number | null;
  /** @format int32 */
  awayScorePrediction?: number | null;
  city?: string | null;
  /** @format int32 */
  currentAwayTeamRank?: number | null;
  /** @format int32 */
  currentHomeTeamRank?: number | null;
  /** @format date */
  endDate?: string | null;
  /** @format time */
  endTimeCentral?: string | null;
  /** @format int64 */
  endTimeUtc?: number | null;
  forfeitEnum?: ForfeitType;
  gameBroadcast?: string | null;
  gameHash?: string | null;
  gameType?: GameType;
  /** @format int32 */
  homeOrgKey?: number | null;
  homeOrganization?: OrganizationResponse;
  /** @format int32 */
  homeScore?: number | null;
  /** @format int32 */
  homeScorePrediction?: number | null;
  imported?: GameOverride;
  isForfeit: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  lastUpdated?: number | null;
  location?: string | null;
  overrides?: GameOverride;
  seasonPhase?: SeasonPhaseResponse;
  /** @format int64 */
  seasonPhaseKey?: number | null;
  sport: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  /** @format date */
  startDate?: string | null;
  /** @format time */
  startTimeCentral?: string | null;
  startTimeTbd: boolean;
  /** @format int64 */
  startTimeUtc?: number | null;
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  ticketLink?: string | null;
  /** @format int32 */
  year: number;
}

export interface AdminGameResRelatedScheduleResponseRelatedPagedData {
  list?: AdminGameRes[];
  pagination?: Pagination;
  relatedModel?: RelatedScheduleResponse;
}

export interface AdminPersonBioRes {
  personBios: PersonBioInfoRes;
  /** @format int64 */
  personKey: number;
}

export interface AdminPersonProfileRes {
  classInfo: PersonClassInfoRes;
  defaultAsset?: AssetResponse;
  defaultPersonSport?: SimplePersonSportRes;
  defaultSport?: SportResponseRecord;
  defaultVitals?: PersonDefaultMeasurementsRes;
  highSchool?: OrganizationResponse;
  hometown?: CityResponseRecord;
  latestPlayerPrimaryPosition?: SimplePositionRes;
  latestPlayerPso?: SimplePersonSportOrgRes;
  latestPlayerPsoOrganzation?: OrganizationResponse;
  latestPlayerPsoStatus?: PsoPlayerStatusRes;
  latestPlayerPsoTransferInfo?: PsoTransferInfoRes;
  latestPlayerSecondaryPosition?: SimplePositionRes;
  latestRecruitment?: RecruitmentResponse;
  latestRecruitmentDestination?: OrganizationResponse;
  latestRecruitmentSource?: OrganizationResponse;
  ncaaId?: string | null;
  person: SimplePersonResponse;
}

export interface ApiPersonSportOrgRes {
  agentInfo?: PsoAgentInfoRes;
  coachStatus?: PsoCoachStatusRes;
  personSportOrg: SimplePersonSportOrgRes;
  playerStatus?: PsoPlayerStatusRes;
  transferInfo?: PsoTransferInfoRes;
}

export interface ApiPersonSportOrgResPagedData {
  list?: ApiPersonSportOrgRes[];
  pagination?: Pagination;
}

export interface ApiPersonSportRes {
  organizations?: SimpleOrganizationResponse[] | null;
  personSport: SimplePersonSportRes;
  positions?: SimplePositionRes[] | null;
  psos?: ApiPersonSportOrgResPagedData;
  sport: SportResponseRecord;
}

export interface ApiPersonSportResSimplePersonResponseRelatedPagedData {
  list?: ApiPersonSportRes[];
  pagination?: Pagination;
  relatedModel?: SimplePersonResponse;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface CityResponseRecord {
  country?: CountryResponseRecord;
  /** @format int32 */
  key: number;
  name?: string;
  state?: StateResponseRecord;
}

export enum ClassYear {
  Unknown = 'Unknown',
  Freshman = 'Freshman',
  RedShirtFreshman = 'RedShirt_Freshman',
  Sophomore = 'Sophomore',
  RedShirtSophomore = 'RedShirt_Sophomore',
  Junior = 'Junior',
  RedShirtJunior = 'RedShirt_Junior',
  Senior = 'Senior',
  RedShirtSenior = 'RedShirt_Senior',
}

export enum ClassificationType {
  Unknown = 'Unknown',
  League = 'League',
  State = 'State',
  Conference = 'Conference',
  Division = 'Division',
  Association = 'Association',
  Region = 'Region',
  District = 'District',
  Class = 'Class',
  Metro = 'Metro',
  Section = 'Section',
}

export interface ConferenceResponse {
  abbreviation?: string | null;
  asset?: KeyUrlBase;
  /** @format int64 */
  assetKey?: number | null;
  assetRes?: AssetResponse;
  isGroupOfFive: boolean;
  isPowerFive: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface CountryResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface CurrentPreviousAndNextGamesRes {
  current?: ScoreboardResponse;
  next?: ScoreboardResponse;
  previous?: ScoreboardResponse;
}

export interface DraftSimpleResponse {
  collegeOrganization?: OrganizationResponse;
  compensatory: boolean;
  forfeited: boolean;
  industryComparison?: PlayerRatingAllResponse[] | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationResponse;
  /** @format int32 */
  overallPick?: number | null;
  /** @format int32 */
  pick?: number | null;
  rosterRating?: RatingResponse;
  /** @format int32 */
  round: number;
  /** @format int32 */
  sportKey: number;
  supplementary: boolean;
  traded: boolean;
  /** @format int32 */
  year: number;
}

export enum ForfeitType {
  Unknown = 'Unknown',
  HomeTeamForfeit = 'HomeTeamForfeit',
  AwayTeamForfeit = 'AwayTeamForfeit',
  DoubleForfeit = 'DoubleForfeit',
}

export interface GameOddsResponse {
  /** @format double */
  awayHandicap?: number | null;
  /** @format int32 */
  awayMoneylineOdds?: number | null;
  /** @format double */
  homeHandicap?: number | null;
  /** @format int32 */
  homeMoneylineOdds?: number | null;
  /** @format double */
  overUnderTotal?: number | null;
}

export interface GameOverride {
  /** @format int32 */
  awayOrgKey?: number | null;
  /** @format int32 */
  awayScore?: number | null;
  /** @format int32 */
  awayScorePrediction?: number | null;
  /** @format double */
  awayWinProbability?: number | null;
  city?: string | null;
  /** @format int32 */
  currentAwayTeamRank?: number | null;
  /** @format int32 */
  currentHomeTeamRank?: number | null;
  /** @format int64 */
  endTimeUtc?: number | null;
  forfeitType?: ForfeitType;
  gameBroadcast?: string | null;
  gameType?: GameType;
  /** @format int32 */
  highestRanking?: number | null;
  /** @format int32 */
  homeOrgKey?: number | null;
  /** @format int32 */
  homeScore?: number | null;
  /** @format int32 */
  homeScorePrediction?: number | null;
  /** @format double */
  homeWinProbability?: number | null;
  /** @format int32 */
  lastUpdated?: number | null;
  location?: string | null;
  /** @format int64 */
  seasonPhaseKey?: number | null;
  /** @format int32 */
  sportKey: number;
  startTimeTbd: boolean;
  /** @format int64 */
  startTimeUtc?: number | null;
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  ticketLink?: string | null;
  /** @format int32 */
  year: number;
}

export enum GameStatuses {
  Unknown = 'Unknown',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Delayed = 'Delayed',
  Postponed = 'Postponed',
  Completed = 'Completed',
}

export enum GameType {
  Unknown = 'Unknown',
  RegularSeason = 'RegularSeason',
  Playoff = 'Playoff',
  Exhibition = 'Exhibition',
  Jamboree = 'Jamboree',
}

export enum InterestLevel {
  None = 'None',
  ScoutLook = 'ScoutLook',
  Interested = 'Interested',
  HighChoice = 'HighChoice',
  TopTarget = 'TopTarget',
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export enum MappingSource {
  Massey = 'Massey',
  MaxPreps = 'MaxPreps',
  CalPreps = 'CalPreps',
  Nfhs = 'Nfhs',
  Nces = 'Nces',
  TfsSports = 'TfsSports',
  Si = 'Si',
  Espn = 'Espn',
  TfsSportsCoach = 'TfsSportsCoach',
  Rivals = 'Rivals',
  CollegeContact = 'CollegeContact',
  NcaaId = 'NcaaId',
}

export interface OrgTypeClassHierarchyRes {
  classificationType: ClassificationType;
  displayName: string;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name: string;
  organizationType: OrganizationType;
  /** @format int32 */
  parentOrgTypeClassHierarchyKey?: number | null;
  path?: string | null;
  /** @format int64 */
  relatedKey?: number | null;
  /** @format int32 */
  rootOrgTypeClassHierarchyKey?: number | null;
  status: OrgTypeClassHierarchyStatus;
}

export enum OrgTypeClassHierarchyStatus {
  Unknown = 'Unknown',
  Active = 'Active',
  Removed = 'Removed',
}

export interface OrganizationRankingPhaseRequest {
  /** @format int32 */
  associatedPressPoints?: number | null;
  /** @format int32 */
  associatedPressRanking?: number | null;
  associatedPressTrend?: RankingTrend;
  /** @format int32 */
  associatedPressTrendNumber?: number | null;
  /** @format int32 */
  coachesPollPoints?: number | null;
  /** @format int32 */
  coachesPollRanking?: number | null;
  coachesPollTrend?: RankingTrend;
  /** @format int32 */
  coachesPollTrendNumber?: number | null;
  /** @format int64 */
  gameKey?: number | null;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  playoffRanking?: number | null;
  playoffTrend?: RankingTrend;
  /** @format int32 */
  playoffTrendNumber?: number | null;
  /** @format int64 */
  seasonPhaseKey: number;
}

export interface OrganizationRankingPhaseResponse {
  /** @format int32 */
  associatedPressPoints?: number | null;
  /** @format int32 */
  associatedPressRanking?: number | null;
  associatedPressTrend?: RankingTrend;
  /** @format int32 */
  associatedPressTrendNumber?: number | null;
  /** @format int32 */
  coachesPollPoints?: number | null;
  /** @format int32 */
  coachesPollRanking?: number | null;
  coachesPollTrend?: RankingTrend;
  /** @format int32 */
  coachesPollTrendNumber?: number | null;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  playoffRanking?: number | null;
  playoffTrend?: RankingTrend;
  /** @format int32 */
  playoffTrendNumber?: number | null;
  /** @format int64 */
  seasonPhaseKey?: number | null;
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PersonBioInfoRes {
  bio?: string | null;
  bioCollegeRecruit?: string | null;
  bioProProspect?: string | null;
}

export interface PersonClassInfoRes {
  /** @format int32 */
  almaMaterClassYear?: number | null;
  /** @format int32 */
  almaMaterKey?: number | null;
  /** @format int32 */
  classYear?: number | null;
  degree?: string | null;
  /** @format int32 */
  highSchoolAlmaMaterKey?: number | null;
  /** @format int32 */
  highSchoolOrgKey?: number | null;
}

export interface PersonConnectionFullResponse {
  connectedCollegeOrganization?: OrganizationResponse;
  connectedDraft?: DraftSimpleResponse;
  connectedPlayer?: SmallPlayerResponse;
  connectedRating?: RatingResponse;
  connectedRosterRating?: RatingResponse;
  connection?: PersonConnectionResponse;
}

export interface PersonConnectionRequestV2 {
  /** @format int64 */
  connectedPersonKey: number;
  /** @minLength 1 */
  description: string;
}

export interface PersonConnectionResponse {
  /** @format int64 */
  connectedPersonKey: number;
  connectedPersonSport?: PersonSportResponseRecord;
  description?: string;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sportKey: number;
}

export interface PersonDefaultMeasurementsRes {
  formattedHeight?: string | null;
  /** @format double */
  height?: number | null;
  /** @format double */
  weight?: number | null;
}

export interface PersonSportResponseRecord {
  /** @format int64 */
  currentPersonSportRankingKey?: number | null;
  /** @format int64 */
  currentRosterPersonSportRankingKey?: number | null;
  defaultSport: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
}

export interface PlayerRatingAllResponse {
  change?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  classYear?: number | null;
  fiveStarPlus: boolean;
  link?: string | null;
  nearlyFiveStarPlus: boolean;
  /** @format int32 */
  overallRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format int32 */
  rankingKey?: number | null;
  rankingType: RankingType;
  /** @format int32 */
  rankingYear: number;
  /** @format double */
  rating?: number | null;
  sport?: string | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  type?: string;
}

export enum PlayerRatingChange {
  Decrease = 'Decrease',
  None = 'None',
  Increase = 'Increase',
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface PsoAgentInfoRes {
  /** @format int64 */
  agencyKey?: number | null;
  /** @format int64 */
  agentKey?: number | null;
  noAgent: boolean;
  /** @format int64 */
  secondaryAgencyKey?: number | null;
  /** @format int64 */
  secondaryAgentKey?: number | null;
}

export interface PsoCoachStatusRes {
  deceased: boolean;
  endOfTeam: boolean;
  fired: boolean;
  promoted: boolean;
  resigned: boolean;
  /** @format double */
  salary?: number | null;
  salaryUrl?: string | null;
}

export interface PsoPlayerStatusRes {
  blueShirt: boolean;
  classYear?: ClassYear;
  deceased: boolean;
  /** @format int32 */
  depth?: number | null;
  draftParticipant: boolean;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  endOfTeam: boolean;
  freeYear: boolean;
  grayShirt: boolean;
  /** @format int32 */
  jerseyNumber?: number | null;
  medical: boolean;
  optedOut: boolean;
  redShirt: boolean;
  transfer: boolean;
  walkOn: boolean;
}

export interface PsoTransferInfoRes {
  freeTransferYear: boolean;
  transfer: boolean;
  /** @format date-time */
  transferDate?: string | null;
  withdrawnTransfer: boolean;
  /** @format date-time */
  withdrawnTransferDate?: string | null;
}

export interface RankInformationResponse {
  /** @format int32 */
  associatedPressRanking?: number | null;
  /** @format int32 */
  coachesPollRanking?: number | null;
  conferenceAbbreviation?: string | null;
  conferenceRecord?: string | null;
  /** @format int32 */
  nationalRank?: number | null;
  record?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export enum RankingTrend {
  NoChange = 'NoChange',
  Up = 'Up',
  Down = 'Down',
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
  TeamComposite = 'TeamComposite',
  AllTimeTeamComposite = 'AllTimeTeamComposite',
  ProjectedImpact = 'ProjectedImpact',
}

export interface RatingResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  nearlyFiveStarPlus: boolean;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  sport?: AttributeResponse;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  year?: number | null;
}

export interface RecruitmentResponse {
  /** @format date-time */
  announcementDate?: string | null;
  draft: boolean;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  /** @format int32 */
  interestCount: number;
  /** @format int64 */
  key: number;
  maxInterestLevel?: InterestLevel;
  oracleLocked: boolean;
  /** @format int32 */
  organizationKey?: number | null;
  /** @format int64 */
  personSportKey: number;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /** @format int64 */
  primaryInterestKey?: number | null;
  /** @format int64 */
  rpmOneKey?: number | null;
  /** @format int64 */
  rpmTwoKey?: number | null;
  shortTermSignee: boolean;
  showRpmPercentage?: boolean | null;
  status: RecruitmentStatus;
  transfer: boolean;
  /** @format int32 */
  year: number;
}

export enum RecruitmentStatus {
  None = 'None',
  Decommitted = 'Decommitted',
  Committed = 'Committed',
  Signed = 'Signed',
  Enrolled = 'Enrolled',
}

export interface RelatedScheduleResponse {
  /** @format int32 */
  orgKey: number;
  organization?: OrganizationResponse;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface RelatedTeamRankingResponse {
  childClassifications?: OrgTypeClassHierarchyRes[] | null;
  /** @format int32 */
  conferenceKey?: number | null;
  currentClassification?: OrgTypeClassHierarchyRes;
  filter?: string | null;
  /** @format int64 */
  lastUpdated?: number | null;
  /** @format int32 */
  orgTypeClassHierarchyKey?: number | null;
  organizationType: OrganizationType;
  siblingClassifications?: OrgTypeClassHierarchyRes[] | null;
  /** @format int32 */
  sportKey?: number | null;
  /** @format int32 */
  stateKey?: number | null;
  /** @format int32 */
  totalResults: number;
  /** @format int32 */
  year?: number | null;
}

export interface RenGameReq {
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  awayOrgKey?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  awayScore?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  awayScorePrediction?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  city?: string | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  currentAwayTeamRank?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  currentHomeTeamRank?: number | null;
  /** @format date */
  endDate?: string | null;
  /** @format time */
  endTimeCentral?: string | null;
  forfeitType?: ForfeitType;
  /**
   * @minLength 0
   * @maxLength 255
   */
  gameBroadcast?: string | null;
  gameType: GameType;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  homeOrgKey?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  homeScore?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  homeScorePrediction?: number | null;
  /**
   * @format int64
   * @min 1
   */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  location?: string | null;
  /**
   * @format int64
   * @min 1
   */
  seasonPhaseKey?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
  /** @format date */
  startDate?: string | null;
  /** @format time */
  startTimeCentral?: string | null;
  startTimeTbd: boolean;
  /**
   * @minLength 0
   * @maxLength 255
   */
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  /**
   * @minLength 0
   * @maxLength 500
   */
  ticketLink?: string | null;
  /**
   * @format int32
   * @min 1800
   * @max 3000
   */
  year: number;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface ScoreboardResponse {
  activeGameStatus?: ActiveGameStatusResponse;
  awayRankingInformation?: RankInformationResponse;
  /** @format int32 */
  awayTeamOrgKey?: number | null;
  awayTeamOrganization?: OrganizationResponse;
  /** @format int32 */
  awayTeamScore?: number | null;
  /** @format int32 */
  awayTeamScorePrediction?: number | null;
  /** @format double */
  awayTeamWinPercentage?: number | null;
  /** @format double */
  awayTeamWinProbability?: number | null;
  broadcast?: string | null;
  city?: string | null;
  currentOrgIsHome?: boolean | null;
  currentOrgIsWinner?: boolean | null;
  /** @format int32 */
  currentOrgKey?: number | null;
  currentOrganization?: OrganizationResponse;
  currentOrganizationRankInformation?: RankInformationResponse;
  forecast?: WeatherInformationResponse;
  forfeitEnum?: ForfeitType;
  homeRankingInformation?: RankInformationResponse;
  /** @format int32 */
  homeTeamOrgKey?: number | null;
  homeTeamOrganization?: OrganizationResponse;
  /** @format int32 */
  homeTeamScore?: number | null;
  /** @format int32 */
  homeTeamScorePrediction?: number | null;
  /** @format double */
  homeTeamWinPercentage?: number | null;
  /** @format double */
  homeTeamWinProbability?: number | null;
  isFinal: boolean;
  isForfeit: boolean;
  /** @format int64 */
  key: number;
  location?: string | null;
  odds?: GameOddsResponse;
  opponentOrganization?: OrganizationResponse;
  opponentOrganizationRankInformation?: RankInformationResponse;
  overUnderResult?: string | null;
  seasonPhase?: SeasonPhaseResponse;
  spreadPrediction?: string | null;
  spreadResult?: string | null;
  startDate?: string;
  /** @format int64 */
  startDateUtc?: number | null;
  startTime?: string;
  startTimeTbd: boolean;
  state?: string | null;
  status?: string | null;
  statusEnum: GameStatuses;
  ticketLink?: string | null;
  winner?: OrganizationResponse;
  /** @format int32 */
  winnerOrgKey?: number | null;
}

export interface ScoreboardResponseRelatedScheduleResponseRelatedPagedData {
  list?: ScoreboardResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedScheduleResponse;
}

export interface SeasonPhaseResponse {
  /** @format date */
  endDate?: string | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  leagueSeasonKey: number;
  name?: string;
  /** @format int32 */
  number?: number | null;
  /** @format int64 */
  parentSeasonPhaseKey?: number | null;
  phaseType: SeasonPhaseType;
  /** @format date */
  startDate?: string | null;
}

export enum SeasonPhaseType {
  Unknown = 'Unknown',
  Round = 'Round',
  MatchRound = 'MatchRound',
}

export interface SimpleOrganizationResponse {
  abbreviation?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  organizationType: OrganizationType;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface SimplePersonResponse {
  /** @format int32 */
  classYear: number;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  slug?: string;
}

export interface SimplePersonSportOrgRes {
  /** @format int64 */
  assetKey?: number | null;
  /** @format int64 */
  key: number;
  /** @format int32 */
  organizationKey: number;
  /** @format int64 */
  personSportKey: number;
  /** @format int32 */
  primaryPositionKey?: number | null;
  /** @format int32 */
  secondaryPositionKey?: number | null;
  /** @format int32 */
  year: number;
  /** @format int32 */
  yearOrder: number;
}

export interface SimplePersonSportRes {
  defaultSport: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  personKey: number;
  /** @format int32 */
  sportKey: number;
}

export interface SimplePositionRes {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name: string;
  positionType: PositionType;
  /** @format int32 */
  sportKey?: number | null;
}

export interface SmallPlayerResponse {
  /** @format int32 */
  classYear?: number | null;
  defaultAsset?: AssetResponse;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  primaryPosition?: AttributeResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  /** @format double */
  weight?: number | null;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StateResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface TeamRankingResponse {
  city?: CityResponseRecord;
  /** @format int32 */
  classificationRank?: number | null;
  conferenceResponse?: ConferenceResponse;
  currentOrganization?: OrganizationResponse;
  /** @format int32 */
  defensiveRank?: number | null;
  /** @format double */
  defensiveScore?: number | null;
  /** @format int32 */
  homeFieldAdvantageRank?: number | null;
  /** @format double */
  homeFieldAdvantageScore?: number | null;
  /** @format int32 */
  hrpKey?: number | null;
  /** @format int32 */
  losses: number;
  /** @format int32 */
  nationalRank?: number | null;
  /** @format int32 */
  offensiveRank?: number | null;
  /** @format double */
  offensiveScore?: number | null;
  /** @format int32 */
  orgKey: number;
  /** @format int32 */
  overallRank?: number | null;
  /** @format int32 */
  overallRankChange?: number | null;
  /** @format int32 */
  overallRankPreviousWeek?: number | null;
  /** @format double */
  overallScore?: number | null;
  /** @format int32 */
  powerRank?: number | null;
  /** @format double */
  powerScore?: number | null;
  record?: string;
  /** @format int32 */
  strengthOfScheduleRank?: number | null;
  /** @format double */
  strengthOfScheduleScore?: number | null;
  /** @format int32 */
  ties?: number | null;
  /** @format int32 */
  totalGames: number;
  /** @format double */
  winPercentage: number;
  /** @format int32 */
  wins: number;
}

export interface TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData {
  list?: TeamRankingResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedTeamRankingResponse;
}

export interface UploadByCsvResponse {
  /** @format int32 */
  added: number;
  /** @format int32 */
  deleted: number;
  /** @format int32 */
  processed: number;
  /** @format int32 */
  rejected: number;
  /** @format int32 */
  updated: number;
}

export interface V2OrganizationsPrevNextDetailParams {
  /** @format int32 */
  orgKey: number;
  /**
   * @format int32
   * @default 1
   */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2OrganizationsScheduleAdminDetailParams {
  /** @format int32 */
  orgKey: number;
  /**
   * @format int32
   * @default 1
   */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2OrganizationsScheduleDetailParams {
  /** @format int32 */
  orgKey: number;
  /**
   * @format int32
   * @default 1
   */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2PersonSportsDetailParams {
  /** @format int64 */
  personKey: number;
  /** @minItems 1 */
  sportKeys?: number[];
}

export interface V2SchedulesImportMasseySchedulesCreateParams {
  /** @default true */
  diff?: boolean;
  orgType?: OrganizationType;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2SchedulesImportMasseySchedulesCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V2SchedulesImportMasseyStatsCreateParams {
  /** @default true */
  diff?: boolean;
  orgType?: OrganizationType;
  source?: MappingSource;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V2SchedulesImportMasseyStatsCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V2SchedulesRefreshOrganizationScheduleStatsUpdateParams {
  orgType?: OrganizationType;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export type V2SeasonRankingPhasesRenovateCreatePayload =
  OrganizationRankingPhaseRequest[];

export interface V2TeamRankingsRankingsListParams {
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit?: number;
  orgType: OrganizationType;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  orgTypeClassHierarachyKey?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
  /**
   * @format int32
   * @min 1800
   * @max 2147483647
   */
  year: number;
}

export interface WeatherInformationResponse {
  conditions?: string | null;
  /** @format int32 */
  temperature?: number | null;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2GamesAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1, game_scorer)
 * @request GET:/rdb/v2/games/{gameKey}/admin
 * @secure
 */
export const v2GamesAdminDetail = (
  client: AxiosInstance,
  gameKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminGameRes>({
      url: `/rdb/v2/games/${gameKey}/admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2GamesDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request DELETE:/rdb/v2/games/{gameKey}
 * @secure
 */
export const v2GamesDelete = (
  client: AxiosInstance,
  gameKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v2/games/${gameKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2GamesRenovateUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1, game_scorer)
 * @request PUT:/rdb/v2/games/renovate
 * @secure
 */
export const v2GamesRenovateUpdate = (
  client: AxiosInstance,
  data: RenGameReq,
  params: RequestParams = {},
) =>
  client
    .request<AdminGameRes>({
      url: `/rdb/v2/games/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2OrganizationsPrevNextDetail
 * @summary Get the current, previous, and next games for a team
 * @request GET:/rdb/v2/organizations/{orgKey}/prev-next
 */
export const v2OrganizationsPrevNextDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V2OrganizationsPrevNextDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<CurrentPreviousAndNextGamesRes>({
      url: `/rdb/v2/organizations/${orgKey}/prev-next`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2OrganizationsScheduleAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1, game_scorer)
 * @request GET:/rdb/v2/organizations/{orgKey}/schedule-admin
 * @secure
 */
export const v2OrganizationsScheduleAdminDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V2OrganizationsScheduleAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminGameResRelatedScheduleResponseRelatedPagedData>({
      url: `/rdb/v2/organizations/${orgKey}/schedule-admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2OrganizationsScheduleDetail
 * @summary Get schedule for a org and season.
 * @request GET:/rdb/v2/organizations/{orgKey}/schedule
 */
export const v2OrganizationsScheduleDetail = (
  client: AxiosInstance,
  { orgKey, ...query }: V2OrganizationsScheduleDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ScoreboardResponseRelatedScheduleResponseRelatedPagedData>({
      url: `/rdb/v2/organizations/${orgKey}/schedule`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V2PeopleBioAdminDetail
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5, profile_edit)
 * @request GET:/rdb/v2/people/{personKey}/bio-admin
 * @secure
 */
export const v2PeopleBioAdminDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminPersonBioRes>({
      url: `/rdb/v2/people/${personKey}/bio-admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V2PeoplePersonConnectionsCreate
 * @summary Creates an Connection using Person Keys and SportKey
 * @request POST:/rdb/v2/people/{personKey}/person-connections
 * @secure
 */
export const v2PeoplePersonConnectionsCreate = (
  client: AxiosInstance,
  personKey: number,
  data: PersonConnectionRequestV2,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/rdb/v2/people/${personKey}/person-connections`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V2PeoplePersonConnectionsDetail
 * @summary Gets Person Connection by Person Key
 * @request GET:/rdb/v2/people/{personKey}/person-connections
 * @secure
 */
export const v2PeoplePersonConnectionsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/rdb/v2/people/${personKey}/person-connections`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonConnection
 * @name V2PeoplePersonConnectionsUpdate
 * @summary Updates a Connection using Person Keys And SportKey
 * @request PUT:/rdb/v2/people/{personKey}/person-connections/{connectionKey}
 * @secure
 */
export const v2PeoplePersonConnectionsUpdate = (
  client: AxiosInstance,
  personKey: number,
  connectionKey: number,
  data: PersonConnectionRequestV2,
  params: RequestParams = {},
) =>
  client
    .request<PersonConnectionFullResponse>({
      url: `/rdb/v2/people/${personKey}/person-connections/${connectionKey}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V2PeopleProfileAdminDetail
 * @summary Gets the essential profile information necessary for the Recruits dashboard header
 * @request GET:/rdb/v2/people/{personKey}/profile-admin
 * @secure
 */
export const v2PeopleProfileAdminDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminPersonProfileRes>({
      url: `/rdb/v2/people/${personKey}/profile-admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Person
 * @name V2PersonProfileAdminDetail
 * @summary Gets the essential profile information necessary for the Recruits dashboard header
 * @request GET:/rdb/v2/person/{personKey}/profile-admin
 * @secure
 */
export const v2PersonProfileAdminDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminPersonProfileRes>({
      url: `/rdb/v2/person/${personKey}/profile-admin`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSport
 * @name V2PersonSportsDetail
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5, profile_edit)
 * @request GET:/rdb/v2/person/{personKey}/sports
 * @secure
 */
export const v2PersonSportsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V2PersonSportsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<ApiPersonSportResSimplePersonResponseRelatedPagedData>({
      url: `/rdb/v2/person/${personKey}/sports`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2SchedulesImportMasseySchedulesCreate
 * @summary  (Roles: developer, cron)
 * @request POST:/rdb/v2/schedules/import-massey-schedules
 * @secure
 */
export const v2SchedulesImportMasseySchedulesCreate = (
  client: AxiosInstance,
  query: V2SchedulesImportMasseySchedulesCreateParams,
  data: V2SchedulesImportMasseySchedulesCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UploadByCsvResponse>({
      url: `/rdb/v2/schedules/import-massey-schedules`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2SchedulesImportMasseyStatsCreate
 * @summary  (Roles: developer, cron)
 * @request POST:/rdb/v2/schedules/import-massey-stats
 * @secure
 */
export const v2SchedulesImportMasseyStatsCreate = (
  client: AxiosInstance,
  query: V2SchedulesImportMasseyStatsCreateParams,
  data: V2SchedulesImportMasseyStatsCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<UploadByCsvResponse>({
      url: `/rdb/v2/schedules/import-massey-stats`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags ScheduleController
 * @name V2SchedulesRefreshOrganizationScheduleStatsUpdate
 * @summary Refresh season stats table OrganizationScheduleStats for a given org/sport/year
 * @request PUT:/rdb/v2/schedules/refresh-organization-schedule-stats
 * @secure
 */
export const v2SchedulesRefreshOrganizationScheduleStatsUpdate = (
  client: AxiosInstance,
  query: V2SchedulesRefreshOrganizationScheduleStatsUpdateParams,
  params: RequestParams = {},
) =>
  client
    .request<CurrentPreviousAndNextGamesRes>({
      url: `/rdb/v2/schedules/refresh-organization-schedule-stats`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags SeasonPhaseControllerV
 * @name V2SeasonRankingPhasesRenovateCreate
 * @summary Updates an organization ranking (by Season Phase - week 1 / week 2...)
 * @request POST:/rdb/v2/season-ranking-phases/renovate
 * @secure
 */
export const v2SeasonRankingPhasesRenovateCreate = (
  client: AxiosInstance,
  data: V2SeasonRankingPhasesRenovateCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingPhaseResponse>({
      url: `/rdb/v2/season-ranking-phases/renovate`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TeamRankingController
 * @name V2TeamRankingsRankingsList
 * @summary Get all Massey Rankings for an Organization/Sport/Year at a national level (default)
or provide an Organization Type Class Hierarchy key to filter by classification.
 * @request GET:/rdb/v2/team-rankings/rankings
 */
export const v2TeamRankingsRankingsList = (
  client: AxiosInstance,
  query: V2TeamRankingsRankingsListParams,
  params: RequestParams = {},
) =>
  client
    .request<TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData>({
      url: `/rdb/v2/team-rankings/rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);
